<template>
    <template v-if="page">
        <section class="radial-grad tw-flex tw-flex-col xl:tw-min-h-dvh xl:tw-justify-evenly">
            <div class="io-container tw-grid tw-gap-8 xl:tw-my-0 xl:tw-grid-cols-2">
                <div>
                    <Tag
                        v-if="page.name"
                        color="primary"
                        :text="page.name" />
                    <h1
                        v-if="page.title"
                        class="tw-mt-6 tw-text-3xl xl:tw-text-5xl"
                        v-html="page.title"></h1>
                </div>
                <div class="tw-flex tw-flex-col tw-items-end tw-justify-end tw-gap-4">
                    <h3
                        v-if="page.subtitle"
                        class="ckeditor tw-mt-auto"
                        v-html="page.subtitle"></h3>
                    <div v-if="page.subtitleButtonText && page.subtitleButtonUrl">
                        <v-btn
                            class="tw-h-12 md:tw-h-14 md:tw-px-7 xl:tw-text-base"
                            color="primary"
                            elevation="0"
                            rounded="pill">
                            <a :href="page.subtitleButtonUrl">{{ page.subtitleButtonText }}</a>
                        </v-btn>
                    </div>
                </div>
            </div>
            <img
                v-if="page.bannerImage"
                alt=""
                class="tw-h-full tw-object-contain max-xl:tw-hidden"
                :src="useMedia(page.bannerImage).original" />
            <img
                v-if="page.bannerImageMobile"
                alt=""
                class="tw-h-80 tw-object-cover xl:tw-hidden"
                :src="useMedia(page.bannerImageMobile).original" />
        </section>
        <section class="io-container radial-grad tw-z-20">
            <h2
                class=""
                v-html="page.experienceTitle"></h2>
            <h3
                class="ckeditor tw-mb-16"
                v-html="page.experienceText"></h3>

            <img
                v-if="page.experienceImage"
                alt=""
                class="tw-h-full tw-object-contain max-xl:tw-max-h-64"
                :src="useMedia(page.experienceImage).original" />
        </section>
        <section class="io-container radial-grad tw-z-20">
            <div class="tw-grid tw-grid-cols-1 xl:tw-grid-cols-12 xl:tw-gap-20">
                <div class="xl:tw-col-span-7">
                    <h2
                        class=""
                        v-html="page.gamificationTitle"></h2>
                    <h3
                        class="ckeditor tw-mb-16"
                        v-html="page.gamificationText"></h3>
                </div>
                <div class="xl:tw-col-span-5">
                    <img
                        v-if="page.gamificationImage"
                        alt=""
                        class="tw-h-full tw-object-cover max-xl:tw-max-h-64 tw-mx-auto"
                        :src="useMedia(page.gamificationImage).original" />
                </div>
            </div>
        </section>
        <section class="io-container tw-z-20">
            <h2
                class="tw-text-center"
                v-html="page.areasTitle"></h2>
            <div class="tw-grid sm:tw-grid-cols-2 xl:tw-grid-cols-3 tw-gap-5">
                <div
                    v-for="area in areas"
                    :key="area.id"
                    class="work-area">
                    <h3
                        class="tw-text-xl"
                        v-html="area.name"></h3>
                    <img
                        v-if="area.icon"
                        alt=""
                        class="tw-h-16 tw-w-16 tw-object-contain"
                        :src="useMedia(area.icon).original" />
                </div>
            </div>
        </section>
        <section id="open-positions"></section>
    </template>
    <template v-else>
        <!-- loader -->
        <Loader />
    </template>
</template>

<script setup lang="ts">
    import { useMedia } from '@/composables/useMedia';
    import { useAppStore } from '@/store/app';

    const appStore = useAppStore();

    const page = computed(() => {
        return appStore.getCareers;
    });

    const areas = computed(() => {
        return appStore.getWorkAreas;
    });
</script>

<style scoped lang="scss">
    .work-area {
        @apply tw-flex tw-items-center tw-justify-between tw-gap-6 tw-rounded-3xl tw-px-10 tw-py-8 tw-font-semibold tw-text-white;

        background: url('@/assets/gradients/work-area.png');
        background-position: center center;
        background-size: cover;
    }
</style>
