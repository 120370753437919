// Utilities
import { defineStore } from 'pinia';
import axios from '@axios';
import qs from 'qs';
import moment from 'moment';
import { useLocale } from 'vuetify';
import { useI18n } from 'vue-i18n';

export const useAppStore = defineStore('app', {
    state: () => ({
        locale: 'pt',
        _isSidebarOpen: false,
        menus: [],
        industries: [],
        solutions: [],
        products: [],
        aboutUs: null as any,
        ourIdentity: null as any,
        recognition: null as any,
        team: [],
        contacts: null as any,
        homepage: null as any,
        clients: [],
        testimonies: [],
        careers: null as any,
        ioacademy: null as any,
        workAreas: [],
        finishedLoading: false,
        news: [],
    }),
    getters: {
        getLocale(): string {
            return this.locale;
        },
        isSidebarOpen(): boolean {
            return this._isSidebarOpen;
        },
        getMenus(): any[] {
            return this.menus;
        },
        getIndustries(): any[] {
            return this.industries;
        },
        getIndustryBySlug: (state) => (slug: string) => {
            return state.industries.find((industry: any) => industry.slug === slug);
        },
        hasFinishedLoading(): boolean {
            return this.finishedLoading;
        },
        getSolutions(): any[] {
            return this.solutions;
        },
        getSolutionBySlug: (state) => (slug: string) => {
            return state.solutions.find((solution: any) => solution.slug === slug);
        },
        getProducts(): any[] {
            return this.products;
        },
        getProductBySlug: (state) => (slug: string) => {
            return state.products.find((product: any) => product.slug === slug);
        },
        getAboutUs(): any {
            return this.aboutUs;
        },
        getTeam(): any[] {
            return this.team;
        },
        getOurIdentity(): any {
            return this.ourIdentity;
        },
        getRecognition(): any {
            return this.recognition;
        },
        getContacts(): any {
            return this.contacts;
        },
        getHomepage(): any {
            return this.homepage;
        },
        getClients(): any[] {
            return this.clients;
        },
        getTestimonies(): any[] {
            return this.testimonies;
        },
        getNews(): any[] {
            return this.news;
        },
        getCareers(): any {
            return this.careers;
        },
        getIOAcademy(): any {
            return this.ioacademy;
        },
        getWorkAreas(): any[] {
            return this.workAreas;
        },
    },
    actions: {
        setLocale(locale: string) {
            if (locale.includes('pt')) locale = 'pt';
            else locale = 'en';
            console.log('setLocale', locale);

            this.locale = locale;
            moment.locale(locale);
            const { current } = useLocale();
            current.value = locale;
            const i18n = useI18n();
            i18n.locale.value = locale;
        },
        setIsSidebarOpen(value: boolean) {
            this._isSidebarOpen = value;
        },
        async fillStore() {
            // menus
            try {
                const query = {
                    sort: 'order',
                    filters: {
                        parent: {
                            $null: true,
                        },
                    },
                    populate: {
                        children: {
                            fields: ['documentId', 'title', 'page', 'pageContentSlug'],
                            sort: 'order',
                        },
                    },
                    fields: ['documentId', 'title', 'page', 'pageContentSlug'],
                };
                const { data } = await axios.get(`/menus?${qs.stringify(query)}`);
                this.menus = data.data.map((menu: any) => {
                    if (menu.page === 'none') menu.page = null;
                    return {
                        ...menu,
                    };
                });
            } catch (error) {
                console.error('Could not fetch menus', error);
            }

            // industry
            try {
                const query = {
                    populate: ['bannerImage', 'contentCards.icon', 'products.logo', 'clients.image', 'projects.image', 'projects.tags', 'contentImages.image', 'contentImages.icon'],
                    pagination: {
                        pageSize: 100,
                    },
                };
                const { data } = await axios.get(`/industries?${qs.stringify(query)}`);
                this.industries = data.data;
            } catch (error) {
                console.error('Could not fetch industries', error);
            }

            // solutions
            try {
                const query = {
                    populate: ['bannerImage', 'expansionPanels', 'products.logo', 'projects.image', 'projects.tags', 'bottomBannerImage'],
                    pagination: {
                        pageSize: 100,
                    },
                };
                const { data } = await axios.get(`/solutions?${qs.stringify(query)}`);
                this.solutions = data.data;
            } catch (error) {
                console.error('Could not fetch solutions', error);
            }

            // products
            try {
                const query = {
                    populate: ['logo', 'bannerImage', 'bannerButtons', 'contentImage', 'features.icon', 'characteristicsImage', 'characteristics.icon', 'news.image', 'partners.image'],
                    pagination: {
                        pageSize: 100,
                    },
                };
                const { data } = await axios.get(`/products?${qs.stringify(query)}`);
                this.products = data.data;
            } catch (error) {
                console.error('Could not fetch products', error);
            }

            // about us
            try {
                const query = {
                    populate: ['bannerImage', 'processes.image'],
                };
                const { data } = await axios.get(`/about-us?${qs.stringify(query)}`);
                this.aboutUs = data.data;
            } catch (error) {
                console.error('Could not fetch about us', error);
            }

            // team
            try {
                const query = {
                    populate: ['image'],
                    pagination: {
                        pageSize: 100,
                    },
                    sort: ['order:asc'],
                };
                const { data } = await axios.get(`/teams?${qs.stringify(query)}`);
                this.team = data.data;
            } catch (error) {
                console.error('Could not fetch team', error);
            }

            // our identity
            try {
                const query = {
                    populate: ['bannerImage', 'bannerImageMobile', 'excellenceBases.icon', 'responsabilities.image', 'timeline'],
                };
                const { data } = await axios.get(`/our-identity?${qs.stringify(query)}`);
                this.ourIdentity = data.data;
            } catch (error) {
                console.error('Could not fetch our identity', error);
            }

            // recognition
            try {
                const query = {
                    populate: ['cards.image', 'partners.image', 'recognition.image', 'clients.image'],
                };
                const { data } = await axios.get(`/recognition?${qs.stringify(query)}`);
                this.recognition = data.data;
            } catch (error) {
                console.error('Could not fetch recognition', error);
            }

            // contact
            try {
                const query = {
                    populate: ['socialNetworks.icon'],
                };
                const { data } = await axios.get(`/contact?${qs.stringify(query)}`);
                this.contacts = data.data;
            } catch (error) {
                console.error('Could not fetch contacts', error);
            }

            // homepage
            try {
                const query = {
                    populate: ['bannerDesktopImage', 'bannerMobileImage', 'bannerButtons', 'recognitions.image', 'contentCards.image', 'solutions.logo', 'products.logo'],
                };
                const { data } = await axios.get(`/homepage?${qs.stringify(query)}`);
                this.homepage = data.data;
            } catch (error) {
                console.error('Could not fetch homepage', error);
            }

            // news
            try {
                const query = {
                    sort: ['publishDate:desc'],
                    populate: ['image', 'tags'],
                    pagination: {
                        pageSize: 5,
                        page: 1,
                    },
                };
                const { data } = await axios.get(`/news?${qs.stringify(query)}`);
                this.news = data.data
            } catch (error) {
                console.error('Could not fetch last news', error);
            }

            // clients
            try {
                const query = {
                    populate: ['image'],
                    filters: {
                        isClient: true
                    },
                    pagination: {
                        pageSize: 100,
                    },
                };
                const { data } = await axios.get(`/partners?${qs.stringify(query)}`);
                this.clients = data.data;
            } catch (error) {
                console.error('Could not fetch clients', error);
            }

            // testimonies
            try {
                const query = {
                    populate: ['partner.image'],
                    filters: {
                        text: {
                            $ne: ''
                        },
                        partner: {
                            $ne: null
                        }
                    },
                    pagination: {
                        pageSize: 100,
                    },
                };
                const { data } = await axios.get(`/testimonies?${qs.stringify(query)}`);
                this.testimonies = data.data;
            } catch (error) {
                console.error('Could not fetch testimonies', error);
            }

            // careers
            try {
                const query = {
                    populate: ['bannerImage', 'experienceImage', 'gamificationImage'],
                };
                const { data } = await axios.get(`/career?${qs.stringify(query)}`);
                this.careers = data.data;
            } catch (error) {
                console.error('Could not fetch careers', error);
            }

            // ioacademy
            try {
                const query = {
                    populate: ['slider', 'aboutImage', 'offers.points', 'offers.icon', 'areas.icon', 'admissionImage'],
                };
                const { data } = await axios.get(`/io-academy?${qs.stringify(query)}`);
                this.ioacademy = data.data;
            } catch (error) {
                console.error('Could not fetch ioacademy', error);
            }

            // work areas
            try {
                const query = {
                    populate: ['icon'],
                    pagination: {
                        pageSize: 100,
                    },
                };
                const { data } = await axios.get(`/areas?${qs.stringify(query)}`);
                this.workAreas = data.data;
            } catch (error) {
                console.error('Could not fetch work areas', error);
            }



            this.finishedLoading = true;
        },
    },
});
